<template>
  <div class="commonCode">
    <div class="commonCode-t"><img src="./img/banner.png" alt="" /></div>
    <div class="commonCode-b">
      <div class="commonCode-b-body">
        <div class="title">
          <div class="title-l">仅限本人使用</div>
          <div class="title-r" @click="refresh">
            <img src="./img/refresh.png" alt="" />
          </div>
        </div>
        <div class="code">
          <!-- <vue-qr :text="imgUrl" :size="size"></vue-qr> -->
          <img :src="imgUrl" alt="" />
        </div>
        <div class="info">
          <div class="info-l"><img src="./img/info.png" alt="" /></div>
          <div class="info-r">如扫码失败，请调亮手机屏幕亮度</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vueQr from "vue-qr";
import { getCodeUrl } from "./api.js";
import { gloabalCount } from "@/utils/common";

export default {
  // components: { vueQr },
  data() {
    return {
      // size: "",
      imgUrl: "",
    };
  },
  computed: {
    // communityId() {
    //   return this.$store.state.communityId;
    // },
    userId() {
      return this.$store.state.userId;
    },
    // roomId() {
    //   return this.$store.state.houseId;
    // },
    // tenantId() {
    //   return this.$store.state.tenantId;
    // },
    // assetId() {
    //   return this.$store.state.assetId;
    // },
  },
  async created() {
    // this.size = (document.documentElement.clientWidth / 375) * 211;
    await this.getCode();
  },
  mounted() {
    gloabalCount("", 89, 1);
  },
  methods: {
    async getCode() {
      let params = {
        page: "pages/targetUrl/index",
        scene: `4&7&param:${JSON.stringify({ userId: this.userId })}`,
      };
      let res = await this.$axios.post(getCodeUrl, params);
      if (res.code === 200) {
        this.imgUrl = res.data;
      }
    },
    refresh() {
      this.getCode();
    },
  },
};
</script>
<style lang="less" scoped>
.commonCode {
  box-sizing: border-box;
  min-height: 100%;
  padding-bottom: 100px;
  background: rgba(0, 0, 0, 0.04);
  .commonCode-t {
    height: 320px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .commonCode-b-body {
    z-index: 99;
    position: relative;
    width: 686px;
    height: 838px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(144, 144, 144, 0.15);
    border-radius: 16px;
    padding: 40px 24px 0;
    box-sizing: border-box;
    margin: -100px auto 0;
    .title {
      display: flex;
      justify-content: space-between;
      align-content: center;
      .title-l {
        font-size: 38px;
        font-weight: 600;
        color: #1a1c34;
        line-height: 52px;
      }
      .title-r {
        width: 36px;
        height: 36px;
        img {
          vertical-align: middle;
          overflow: hidden;
          width: 100%;
          height: 100%;
        }
      }
    }
    .code {
      width: 422px;
      height: 422px;
      margin: 102px auto 78px;
      > img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        vertical-align: middle;
      }
    }
    .info {
      display: flex;
      padding-left: 84px;
      align-content: center;
      .info-l {
        width: 24px;
        height: 32px;
        margin-right: 14px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info-r {
        font-size: 28px;
        font-weight: 400;
        color: #a8a8a8;
        line-height: 40px;
      }
    }
  }
}
</style>
